export const navData = [
  {
    id: 1,
    elementName: "about",
    text: "About",
  },
  {
    id: 2,
    elementName: "work",
    text: "Work",
  },
  {
    id: 3,
    elementName: "story",
    text: "Story",
  },
];
